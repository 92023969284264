import { TimelineMax, TweenMax, Power4 } from 'gsap'

// window.onload = () => {
document.addEventListener('DOMContentLoaded', () => {

  let $main       = document.querySelector("main"),
      $menu       = document.querySelector("nav.menu"),
      $transition = document.querySelector(".transition"),
      $logo       = $menu.querySelector(".logo"),
      $left       = $transition.querySelector(".left"),
      $right      = $transition.querySelector(".right"),
      $header     = document.getElementById("header")

  // Initial transition to show pages
  const transtl = new TimelineMax({
    onComplete: () => {
      TweenMax.set($transition, {
        visibility: 'hidden',
        autoAlpha: 0
      })
      TweenMax.set([
        $left,
        $right
      ], {
        clearProps: 'all'
      })
    }
  })
  transtl.to($right, '1', {
    y: "100%",
    delay: '0.25'
  })
  transtl.to($left, '1', {
    y: "-100%",
    delay: '0.25'
  }, "-=1")

  // Open Menu
  $header.querySelector("nav .open-menu").addEventListener("click", () => {

    const timeline = new TimelineMax({
      onComplete: () => {

        TweenMax.set($main, {
          clearProps: 'opacity, scale'
        })

        let parentMenuWidth = $menu.querySelector("ul").clientWidth
        let subMenus = $menu.querySelectorAll('.sub-menu ul')
        subMenus.forEach(sub => sub.style.width = `${parentMenuWidth}px`)
      }
    })

    timeline.to($main, 1, {
      ease: Power4.easeInOut,
      autoAlpha: 0.8,
      scale: 0.9
    })

    timeline.to($menu, 1.2, {
      ease: Power4.easeInOut,
      x: "0",
      autoAlpha: 1,
    }, '-=0.5')
  })

  // Close Menu
  $menu.querySelector(".close-menu").addEventListener("click", () => {
    let $links = $menu.querySelector('ul').children

    const timeline = new TimelineMax({
      onComplete: () => {

        TweenMax.set([
          $links,
          $menu,
          $logo,
          $left,
          $right
        ], {
          clearProps: 'all'
        })
        TweenMax.set($transition, {
          visibility: 'hidden',
          autoAlpha: 0
        })
      }
    })

    timeline.set($transition, {
      visibility: 'visible',
      autoAlpha: 1
    })

    timeline.staggerTo($links, '0.25', {
      ease: Power4.easeInOut,
      autoAlpha: 0,
      y: "10%"
    }, '-0.1')

    timeline.to($logo, '0.5', {
      ease: Power4.easeInOut,
      autoAlpha: 0,
      x: "10%"
    }, "-=0.5")

    timeline.to($menu, '0.5', {
      autoAlpha: 0
    })

    timeline.to($right, '0.5', {
      y: "100%"
    })

    timeline.to($left, '0.5', {
      y: "-100%"
    }, "-=0.5")
  })

  // Link click
  let links = $menu.querySelectorAll("a")
  links.forEach(link => {
    link.addEventListener("click", e => {
      e.preventDefault()
      let $links  = $menu.querySelector('ul').children,
          href = link.getAttribute("href")

      const timeline = new TimelineMax({
        onComplete: () => {
          window.location = href
        }
      })

      timeline.set($transition, {
        visibility: 'visible',
        autoAlpha: 1
      })

      TweenMax.set([
        $left,
        $right
      ], {
        clearProps: 'all'
      })

      timeline.staggerTo($links, '0.25', {
        ease: Power4.easeInOut,
        autoAlpha: 0,
        y: "10%"
      }, '-0.1')

      timeline.to($logo, '0.5', {
        ease: Power4.easeInOut,
        autoAlpha: 0,
        x: "10%"
      }, "-=0.5")

      timeline.to($menu, '0.5', {
        autoAlpha: 0
      })
    })
  })

  // Forms

  // Add http:// to website url
  var checkURL = function(e) {
    let target = e.target
    let url = target.value
    if (url.length > 0 && !~url.indexOf("http")) {
      url = "http://" + url
    }
    return target.value = url
  }
  const websiteEl = document.getElementById('website')
  if(websiteEl) websiteEl.addEventListener('blur', checkURL)
})
